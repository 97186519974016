import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCustomFacets, getFilterQueries } from 'browse3/store/UrlSlice';
import { useAppDispatch } from 'browse3/store/hooks';
import { fetchFeaturedContent, getFeaturedContent } from 'browse3/store/FeaturedContentSlice';
import { getFeaturedContentKey } from 'browse3/helpers';
import FeaturedContentCard from 'browse3/components/FeaturedContentCard';
import './FeaturedContent.scss';

const FeaturedContentWrapper: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const urlFilters = useSelector(getFilterQueries);
  const customFacets = useSelector(getCustomFacets);
  const featuredContent = useSelector(getFeaturedContent);

  useEffect(() => {
    if (urlFilters) dispatch(fetchFeaturedContent(getFeaturedContentKey(urlFilters, customFacets)));
  }, [urlFilters]);

  const featuredContentCards: any[] = [];
  featuredContent.map((content, index) => {
    featuredContentCards.push(
      <FeaturedContentCard
        name={content.title}
        url={content.url}
        displayType={content.displayType}
        updatedAt={content.updatedAt}
        viewCount={content.viewCount}
        description={content.description}
        contentType={content.contentType}
        key={index}
      ></FeaturedContentCard>
    );
  });

  if (featuredContentCards.length === 0) return null;

  return <div className="featured-content">{featuredContentCards}</div>;
};

export default FeaturedContentWrapper;
