import React, { FunctionComponent, useEffect, useState } from 'react';
import { ForgeBanner, ForgeButton, ForgeIcon, ForgeScaffold } from '@tylertech/forge-react';
import { useSelector } from 'react-redux';
import { currentUserHasRight } from 'common/current_user';
import { getCurrentDomain } from 'common/currentDomain';
import DomainRights from 'common/types/domainRights';
import FeatureFlags from 'common/feature_flags';
import I18n from 'common/i18n';
import useScreenSize from 'common/screen_size/useScreenSize';
import FacetSidebar from 'common/components/FacetSidebar';
import MobileFacetSidebarTrigger from 'common/components/FacetSidebar/MobileFacetSidebarTrigger';
import AssetSearchAutocomplete from '../assetSearchAutocomplete';
import AssetList from './components/AssetList';
import ResultsPaginator from './components/ResultsPaginator';
import SortResults from './components/SortResults';
import NoResultsPage from './components/NoResultsPage';
import FeaturedContentBanner from './components/FeaturedContentBanner';
import FeaturedContentWrapper from './components/FeaturedContentWrapper';
import FilterChips from './components/FilterChips';
import { fetchAssetsByParameters, getAssetsAreLoading, getResultsTotalSize } from './store/AssetSlice';
import {
  fetchBrowseConfig,
  getBrowseConfig,
  getBrowseConfigFilters,
  getFederations,
  getTags,
  setFilters
} from './store/BrowseConfigSlice';
import { fetchCatalogConfig, fetchFederationFilterCname, getCatalogConfig } from './store/CatalogConfigSlice';
import {
  fetchCatalogLandingPageConfig,
  getCatalogLandingPageConfig
} from './store/CatalogLandingPageConfigSlice';
import { useAppDispatch } from './store/hooks';
import {
  updateQInUrl,
  updatePageNumberInUrl,
  updatePageSizeInUrl,
  updateSortGivenCatalogOrder,
  updateTagInUrl,
  getFilterQueries,
  getCustomFacets,
  addFilterFromUrlOnLoad,
  getActiveFiltersCount,
  removeAllFiltersAndTagsFromUrl,
  updateFilterQueriesInUrl,
  setAllFilters,
  removeTagFromUrl
} from './store/UrlSlice';
import {
  buildFacetSidebarContent,
  getFeaturedContentKey,
  getParamValueInUrl,
  getSortBy,
  translateDisplayTypeFromUrlToCeteraFormat,
  translateParamToCeteraQueryParam,
  updateOrAddFilter
} from './helpers';
import { Filter } from 'accessibleBrowseFilters/types';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, FilterQuery, FilterUpdateInfo } from './types';
import Markdown from 'markdown-it';

const Browse3App: FunctionComponent = () => {
  const scope = 'controls.browse.browse3';
  const dispatch = useAppDispatch();
  const catalogConfig = useSelector(getCatalogConfig);
  const browseConfig = useSelector(getBrowseConfig);
  const total = useSelector(getResultsTotalSize);
  const activeFiltersCount = useSelector(getActiveFiltersCount);
  const assetsAreLoading = useSelector(getAssetsAreLoading);
  const catalogLandingPageConfig = useSelector(getCatalogLandingPageConfig);
  const urlFilters = useSelector(getFilterQueries);
  const customFacets = useSelector(getCustomFacets);
  const filters = useSelector(getBrowseConfigFilters);
  const tags = useSelector(getTags);
  const federations = useSelector(getFederations);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMobileView = useScreenSize();
  const md = new Markdown({
    linkify: true
  });

  useEffect(() => {
    dispatch(fetchCatalogConfig());
    dispatch(fetchCatalogLandingPageConfig());
    dispatch(fetchBrowseConfig());
  }, []);

  useEffect(() => {
    if (catalogConfig.configLoaded && browseConfig.browseConfigIsLoaded && !browseConfig.filtersAreSet) {
      dispatch(
        setFilters(
          catalogConfig.config.properties?.find((prop) => {
            return prop.name === 'view_types_facet';
          })?.value
        )
      );
    } else if (browseConfig.filtersAreSet) {
      dispatch(setAllFilters(filters));
      applyAllFoundUrlParametersOnLoad();

      // Now that we have any filtering from the URL applied, fetch the assets
      dispatch(fetchAssetsByParameters());
    } else if (catalogConfig.federation_filter.length > 0) {
      // will change with update to browse_config (this call to get the federation filter cname currently breaks stuff)
      // otherwise, if we're not finished loading config and we have a federation filter domain, we need to fetch the cname
      dispatch(fetchFederationFilterCname(catalogConfig.federation_filter));
    }
  }, [catalogConfig, browseConfig]);

  const applyAllFoundUrlParametersOnLoad = () => {
    /* START: This order matters! I think it has something to do with the page setting
    default values for these things, then overriding them when different values are found in the URL.
    The actions dispatched during the overriding puts things into a specific state. I think. */
    const queryInUrl = getParamValueInUrl('q') ?? '';
    const pageNumber = parsePageNumberInUrl();
    const pageSize = parsePageSizeInUrl();
    const tagInUrl = getParamValueInUrl('tags');

    const sortBy = getSortBy(catalogConfig.configQueryParams.order ?? '');

    dispatch(updateQInUrl(queryInUrl));
    dispatch(updateSortGivenCatalogOrder(sortBy));
    dispatch(updatePageNumberInUrl(pageNumber));
    dispatch(updateTagInUrl(tagInUrl));
    dispatch(updatePageSizeInUrl(pageSize));
    /* END*/

    // filters (minus federations)
    const filtersWithoutFederations = browseConfig.filters.filter((f) => {
      return f.param != 'federation_filter';
    });
    filtersWithoutFederations.map((facet) => {
      applyFilteringFromUrlOnLoad(facet.param);
    });

    // federations
    const federationFilter = browseConfig.filters.find((f) => {
      return f.param == 'federation_filter';
    });
    if (federationFilter) {
      applyFederationFilteringFromUrlOnLoad(federationFilter);
    }
  };

  const applyFilteringFromUrlOnLoad = (facetInUrl: string) => {
    let filterToApply: FilterQuery;
    const ceteraParam = translateParamToCeteraQueryParam(facetInUrl);
    let valueOfFacetInUrl = getParamValueInUrl(facetInUrl);
    if (valueOfFacetInUrl) {
      if (facetInUrl === 'limitTo') {
        valueOfFacetInUrl = translateDisplayTypeFromUrlToCeteraFormat(valueOfFacetInUrl);
      }

      filterToApply = {
        queryParam: ceteraParam,
        paramValue: valueOfFacetInUrl
      };

      dispatch(addFilterFromUrlOnLoad(filterToApply));
    }
  };

  const applyFederationFilteringFromUrlOnLoad = (federationFilter: Filter) => {
    let federationToApply: FilterQuery;
    const domainIdInUrl = getParamValueInUrl('federation_filter');
    const currentFederation = federationFilter.options.find((option) => option.value === domainIdInUrl);
    if (currentFederation) {
      let domainCname = currentFederation.text;
      // will change with update to browse_config (this call to getCurrentDomain currently breaks stuff)
      if (domainCname === 'This site only') {
        domainCname = getCurrentDomain();
      }

      federationToApply = {
        queryParam: 'domains',
        paramValue: domainCname
      };
      dispatch(addFilterFromUrlOnLoad(federationToApply));
    }
  };

  const parsePageNumberInUrl = (): number => {
    const pageInUrl = getParamValueInUrl('page');
    return pageInUrl ? parseInt(pageInUrl) : 1;
  };

  // If the pageSize value is not one of the pageSize options, change it to the default.
  const parsePageSizeInUrl = (): number => {
    const pageSizeInUrl = getParamValueInUrl('pageSize');
    const pageSize = pageSizeInUrl ? parseInt(pageSizeInUrl) : DEFAULT_PAGE_SIZE;
    return DEFAULT_PAGE_SIZE_OPTIONS.includes(pageSize) ? pageSize : DEFAULT_PAGE_SIZE;
  };

  const getTitle = (): string => {
    if (!urlFilters) return I18n.t('catalog', { scope });
    const key = getFeaturedContentKey(urlFilters, customFacets);
    const clpConfig = catalogLandingPageConfig.results.find((config) => config.name === key);
    if (clpConfig && clpConfig.headline) return clpConfig.headline;
    return I18n.t('catalog', { scope });
  };

  const getDescriptionSection = () => {
    if (!urlFilters) return null;
    const key = getFeaturedContentKey(urlFilters, customFacets);
    const clpConfig = catalogLandingPageConfig.results.find((config) => config.name === key);
    if (clpConfig && clpConfig.description) {
      const formattedDescription =  {
        __html: md.render(clpConfig.description)
      };
      return <div className="catalog-description" dangerouslySetInnerHTML={formattedDescription} />;
    }
    return null;
  };

  const hideDrawer = () => {
    setIsDrawerOpen(false);
  };

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  const mobileFiltersButtonContent = () => {
    return activeFiltersCount > 0
      ? I18n.t('filter.filters_count', { scope, count: activeFiltersCount })
      : I18n.t('filter.filters', { scope });
  };

  const catalogTitleClass = isMobileView
    ? 'forge-typography--headline4 title-for-mobile'
    : 'forge-typography--headline4';

  const onClearAllFilters = () => {
    dispatch(removeAllFiltersAndTagsFromUrl());
    dispatch(fetchAssetsByParameters());
    hideDrawer();
  };

  const onFilterSelect = (filterParam: string, filterValue: string) => {
    dispatch(updatePageNumberInUrl(1)); // always reset to first page of results with new filter applied
    let newFilterQueries: FilterQuery[] = [];
    let federationCname = '';
    let filterValueForUrl = '';
    const translatedFilterParam = translateParamToCeteraQueryParam(filterParam);

    // for federations, translatedFilterValue needs to be cname
    if (filterParam === 'federation_filter') {
      filterValueForUrl = filterValue; // filterValue comes in as domain ID for federations
      const matchingFederation = federations?.find((fed) => {
        return fed.value.toString() === filterValue;
      });
      federationCname = matchingFederation?.cname ?? '';
    }

    const translatedFilterValue =
      filterParam === 'federation_filter'
        ? federationCname
        : translateDisplayTypeFromUrlToCeteraFormat(filterValue);

    if (urlFilters && urlFilters.length != 0) {
      newFilterQueries = updateOrAddFilter(urlFilters, translatedFilterParam, translatedFilterValue);
    } else if (translatedFilterParam) {
      newFilterQueries.push({
        queryParam: translatedFilterParam,
        paramValue: translatedFilterValue
      });
    }

    const filterUpdateInfo: FilterUpdateInfo = {
      urlParts: {
        filterParam: filterParam,
        filterValue: filterValue,
        filterValueForUrl: filterValueForUrl
      },
      filters: newFilterQueries
    };

    dispatch(updateFilterQueriesInUrl(filterUpdateInfo));
    dispatch(fetchAssetsByParameters());
  };

  const onFilterClear = (filterParam: string) => {
    dispatch(updatePageNumberInUrl(1)); // always reset to first page of results with new filter applied

    const translatedFilterParam = translateParamToCeteraQueryParam(filterParam);
    const newFilterQueries: FilterQuery[] = [];
    // keep other existing filters
    urlFilters?.forEach((filterQuery) => {
      if (filterQuery.queryParam !== translatedFilterParam) {
        newFilterQueries.push(filterQuery);
      }
    });

    const filterUpdateInfo: FilterUpdateInfo = {
      urlParts: {
        filterParam: filterParam,
        filterValue: ''
      },
      filters: newFilterQueries
    };

    dispatch(updateFilterQueriesInUrl(filterUpdateInfo));
    dispatch(fetchAssetsByParameters());
  };

  const onTagSelect = (tagValue: string) => {
    dispatch(updateTagInUrl(tagValue));
    dispatch(updatePageNumberInUrl(1)); // always reset to first page of results with new filter applied
    dispatch(fetchAssetsByParameters());
  };

  const onTagClear = () => {
    dispatch(removeTagFromUrl());
    dispatch(updatePageNumberInUrl(1)); // always reset to first page of results with new filter applied
    dispatch(fetchAssetsByParameters());
  };

  const generateFacetSidebarContent = () => {
    return buildFacetSidebarContent(filters, onFilterSelect, onFilterClear, tags, onTagSelect, onTagClear);
  };

  return (
    <ForgeScaffold>
      <FacetSidebar
        title={I18n.t('filter.filters', { scope })}
        hasMobileView={true}
        isMobileDrawerOpen={isDrawerOpen}
        onMobileDrawerClose={hideDrawer}
        onMobileClearAllFilters={onClearAllFilters}
        children={generateFacetSidebarContent()}
      />
      <div slot={'header'} className="featured-content-banner">
        <div className="browse3-preview-banner">
          {FeatureFlags.value('browse_preview_banner') && (
            <ForgeBanner className="forge-typography--body1">
              <div>
                {I18n.t('browse3_tech_preview_banner', { scope })}
                <ForgeButton type="outlined">
                  <button
                    type="button"
                    onClick={() => {
                      window.location.href = window.location.origin + '/browse';
                    }}
                  >
                    <ForgeIcon name="swap_horiz"></ForgeIcon>
                    <span>{I18n.t('browse3_tech_preview_button', { scope })}</span>
                  </button>
                </ForgeButton>
              </div>
            </ForgeBanner>
          )}
        </div>
        {currentUserHasRight(DomainRights.feature_items) && <FeaturedContentBanner />}
      </div>
      <div slot="body-header" className="browse3-header">
        <div className="catalog-header">
          <div className={catalogTitleClass}>{getTitle()}</div>
          <AssetSearchAutocomplete />
        </div>
        {getDescriptionSection()}
        <FeaturedContentWrapper />
        <div className="catalog-results">
          <div className="results-left-section">
            <div className="forge-typography--body1">
              <strong>{total}</strong>{' '}
              {total === 1 ? I18n.t('result', { scope }) : I18n.t('results', { scope })}
            </div>
            <MobileFacetSidebarTrigger
              activeFacetsCount={activeFiltersCount}
              onMobileDrawerOpen={showDrawer}
            />
            {!isMobileView && <FilterChips />}
          </div>
          <div className="sort-results">
            <SortResults />
          </div>
        </div>
      </div>
      <div slot="body" className="browse3-body">
        <div className="body-with-cards-and-paginator">
          {total == 0 && !assetsAreLoading ? <NoResultsPage /> : <AssetList />}
          <ResultsPaginator />
        </div>
      </div>
    </ForgeScaffold>
  );
};

export default Browse3App;
