// Constants
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 20, 30, 100];

export enum SortType {
  name = 'alpha',
  createdAt = 'newest',
  relevance = 'relevance',
  page_views_total = 'most_accessed',
  updatedAt = 'last_modified'
}

// View type in URL format
export const datasets = 'datasets';
export const charts = 'charts';
export const maps = 'maps';
export const calendars = 'calendars';
export const filters = 'filters';
export const href = 'href';
export const blob = 'blob';
export const forms = 'forms';
export const story = 'story';
export const measures = 'measures';
export const tables = 'tables';

// View type for cetera API call format
export const stories = 'stories';
export const files = 'files';
export const links = 'links';

// Types
export interface Asset {
  name: string;
  description?: string;
  url: string;
  tags?: string[];
  viewCount: number;
  lastUpdated: string;
  sourceDomain?: string;
  scope?: string;
  type: string;
  provenance: string;
  category: string;
  sourceLink?: string;
}

export interface CatalogAsset {
  link: string;
  permalink: string;
  source_link?: string;
  classification: CatalogAssetClassification;
  creator: CatalogAssetUser;
  metadata: CatalogMetadata;
  owner: CatalogAssetUser;
  resource: CatalogResource;
}

export interface CatalogAssetClassification {
  category?: string;
  domain_category?: string;
  domain_metadata?: CatalogDomainMetadata[];
  domain_tags?: string[];
  tags?: string[];
}

export interface CatalogDomainMetadata {
  key: string;
  value: string;
}

export interface CatalogAssetUser {
  display_name: string;
  id: string;
  user_type: string;
}

export interface CatalogMetadata {
  domain: string;
}

export interface CatalogResource {
  attribution?: string;
  attribution_link?: string;
  blob_mime_type?: string;
  columns_datatype: string[];
  columns_description: string[];
  columns_field_name: string[];
  columns_format: CatalogColumnFormat[];
  columns_name: string[];
  contact_email?: string;
  createdAt: string;
  data_updated_at: string;
  description?: string;
  download_count: number;
  hide_from_data_json?: boolean;
  id: string;
  lens_display_type: string;
  lens_view_type: string;
  locked: boolean;
  metadata_updated_at: string;
  name: string;
  page_views: CatalogPageViews;
  parent_fxf: string[];
  provenance: string;
  publication_date: string;
  type: string;
  updatedAt: string;
}
export interface CatalogColumnFormat {
  decimalSeparator: string;
  groupSeparator: string;
}

export interface CatalogPageViews {
  page_views_last_month: number;
  page_views_last_month_log: number | undefined;
  page_views_last_week: number;
  page_views_last_week_log: number | undefined;
  page_views_total: number;
  page_views_total_log: number | undefined;
}

export interface CatalogConfig {
  id: number;
  name: string;
  type: string;
  default: boolean;
  properties: ConfigProperty[];
}

export interface ConfigProperty {
  name: string;
  value: any;
}

export interface CatalogAutocompleteResult {
  display_title: string;
  title: string;
  match_offsets: CatalogAutocompleteMatchOffset[];
}

export interface CatalogAutocompleteMatchOffset {
  start: number;
  length: number;
}

export interface QueriesInUrl {
  filterParam: string;
  filterValue: string;
  filterValueForUrl?: string; // Used when URL value is different than catalog query value (as in federations)
}

export interface ForgeItemValues {
  forgeListItemText: string;
  forgeListItemValue: string;
  urlValue: string;
}

export interface FilterQuery {
  queryParam: string; // this is a Cetera query param (not a url query param)
  paramValue: string;
}

export interface FilterUpdateInfo {
  urlParts: QueriesInUrl;
  filters: FilterQuery[];
}

// Parameters that are sent to cetera to fetch assets
export interface AssetSearchOptions {
  approvalStatus?: string;
  audience?: string[];
  domains?: string;
  explicitlyHidden?: boolean;
  limit?: number;
  order?: string;
  pageNumber?: number;
  published?: boolean;
  q?: string;
  tags?: string;
  filters?: FilterQuery[];
}

export interface FeaturedContentEntry {
  featuredLensUid: string;
  contentType: string;
  description: string;
  position: number;
  title: string;
  url: string;
  updatedAt?: number;
  viewCount?: number;
  displayType: string;
}

export interface ActiveFilter {
  facet: string;
  filterValue: any;
}

export interface BrowseConfig {
  customFacets?: CustomFacet[];
  categories?: Category[];
  tags?: Tags;
  federations?: Federations;
}

export interface CustomFacet {
  param: string;
  description: string;
  options: string[];
  title: string;
}

export interface Category {
  value: string;
  param: string;
  children?: Category[];
}

export interface Tags {
  param: 'tags';
  options: string[];
}

export interface Federations {
  param: 'federation_filter';
  options: FederationInfo[];
}

export interface FederationInfo {
  text: string;
  value: string;
  cname: string;
}

export interface CatalogLandingPageConfig {
  name: string;
  headline: string;
  description: string;
}
