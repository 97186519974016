import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Asset, CatalogAsset, FilterQuery } from '../types';
import { RootState } from './store';
import ceteraUtils from 'common/cetera/utils';

export interface AssetState {
  results: Asset[];
  assetsAreLoading: boolean;
  resultsTotalSize: number;
}

interface CatalogResults {
  results: CatalogAsset[];
  resultSetSize: number;
}

const initialState: AssetState = {
  results: [],
  assetsAreLoading: true,
  resultsTotalSize: 0
};

const combineTags = (asset: CatalogAsset): string[] => {
  const tags = asset.classification.tags || [];
  const domainTags = asset.classification.domain_tags || [];
  return [...tags, ...domainTags];
};

const mapCatalogAssetToAsset = (catalogAsset: CatalogAsset): Asset => {
  return {
    name: catalogAsset.resource.name,
    description: catalogAsset.resource.description,
    url: catalogAsset.permalink,
    tags: combineTags(catalogAsset),
    viewCount: catalogAsset.resource.page_views.page_views_total,
    lastUpdated: catalogAsset.resource.updatedAt,
    type: catalogAsset.resource.type,
    provenance: catalogAsset.resource.provenance,
    category: catalogAsset.classification.domain_category || '',
    sourceDomain: catalogAsset.metadata.domain,
    sourceLink: catalogAsset.source_link
  };
};

/**
 * fetchAssetsByParameters Fetch assets using the parameters stored in state
 *  CatalogResults: This is the return type.
 *  undefined: We don't need to pass anything in for the second argument because we are getting everything
 *  we need to do the fetch from state. However we need to pass undefined in order to use state which is an
 *  optional third argument.
 *  state: This is optional but we need it here to get the state.
 */
export const fetchAssetsByParameters = createAsyncThunk<CatalogResults, undefined, { state: RootState }>(
  'assets/fetchAssetsByParameters',
  async (_, thunkAPI) => {
    const state: RootState = thunkAPI.getState();
    const options = { ...state.catalogConfig.configQueryParams, ...state.url.searchOptions };
    const response = await ceteraUtils.query(options);
    return response;
  }
);

export const AssetSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssetsByParameters.fulfilled, (state, action) => {
        const catalogResults: Asset[] = [];
        if (action.payload.resultSetSize) {
          action.payload.results.map((result: CatalogAsset) => {
            catalogResults.push(mapCatalogAssetToAsset(result));
          });
        }
        state.results = catalogResults;
        state.assetsAreLoading = false;
        state.resultsTotalSize = action.payload.resultSetSize;
      })
      .addCase(fetchAssetsByParameters.pending, (state, action) => {
        if (state.resultsTotalSize > 0) {
          state.assetsAreLoading = true;
        }
      });
  }
});

// Selectors -- return a piece of the state
export const getAssets = (state: RootState) => {
  return state.assets.results;
};
export const getResultsTotalSize = (state: RootState) => {
  return state.assets.resultsTotalSize;
};
export const getAssetsAreLoading = (state: RootState) => {
  return state.assets.assetsAreLoading;
};

export default AssetSlice.reducer;
